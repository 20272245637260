<template>
  <div class="bg-blue-600 text-white p-4 flex justify-between items-center">
    <h1
        class="text-lg font-semibold cursor-pointer underline hover:text-blue-300"
        @click="$emit('edit-quote')"
    >
      {{ quoteName }}
    </h1>

    <div class="flex items-center">
      <button
          v-if="mode"
          class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
          @click="$emit('change-mode')"
      >
        Return To Quote
      </button>
      <button
          v-else
          class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-4"
          @click="quit"
      >
        Quit Quote Builder
      </button>

      <div class="relative">
        <button
            class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center"
            @click="expanded = !expanded"
        >
          <span class="mr-2">Quote Value: £{{ totalQuoteValue.toFixed(2) }}</span>
          <svg
              :class="[expanded ? 'rotate-180' : 'rotate-0']"
              class="h-4 w-4 transform transition-transform duration-200"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 9l-7 7-7-7"
            />
          </svg>
        </button>

        <transition name="fade">
          <div
              v-if="expanded"
              class="absolute right-0 mt-2 bg-white shadow-md rounded-md w-64 overflow-hidden z-10"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Frame
                </th>
                <th class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Value
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(frame, index) in frames"
                  :key="frame.id"
              >
                <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  Frame #{{ index + 1 }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  £{{ frame.price * frame.quantity }}
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </transition>
      </div>

      <button
          class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
          @click="$emit('email-quote')"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-envelope"
            viewBox="0 0 16 16"
            style="height: 24px;width: 30px;"
        >
          <path
              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"
          />
        </svg>
      </button>
      <button
          class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
          @click="$emit('view-quote')"
      >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-file-pdf"
            viewBox="0 0 16 16"
            style="height: 24px;width: 30px;"
        >
          <path
              d="M4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 1h8a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1"
          />
          <path
              d="M4.603 12.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.187-.012.395-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.065.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.6 11.6 0 0 0-1.997.406 11.3 11.3 0 0 1-1.021 1.51c-.29.35-.608.655-.926.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.244.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 5.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z"
          />
        </svg>
      </button>

      <button
          class="bg-blue-800 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
          @click="$emit('add-frame')"
      >
        Add New Frame
      </button>
    </div>
  </div>
</template>

<script>
import {useQuoteStore} from "@/stores/QuoteStore";

export default {
  props: ['quoteName', 'mode'],
  emits: ['edit-quote', 'add-frame', 'change-mode'],
  data() {
    return {
      expanded: false,
      store: {},
      frames: {},
    }
  },
  computed: {
    totalQuoteValue() {
      let store = useQuoteStore();
      let frames = store.frames

      let totalValue = 0;
      frames.forEach(function (item) {
        totalValue += (parseFloat(item.price) * item.quantity)
      })
      return totalValue
    }
  },
  mounted() {
    this.store = useQuoteStore();
    this.frames = this.store.frames

  },
  methods: {
    sendEmail() {
      // Placeholder for sending email logic
      alert('This would send the quote via email.');
    },
    quit() {
      console.log('test')
      window.location = process.env.VUE_APP_API_URL + '/admin/quotes/' + this.store.quoteObj.id
    },
    downloadPDF() {
      // Placeholder for downloading PDF logic
      alert('This would download the quote as a PDF.');
    }
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
  opacity: 0;
}
</style>
